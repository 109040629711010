<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>PERFIL</v-card-title>
    </v-card>

    <v-row class="mb-4">

      <v-col
        cols="12"
        md="5"
        lg="4"
      >

        <profile-bio-panel
          :user-data="userData"
          @reload-data="getUserDataForProfile"
        />
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <!-- <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            Algo
          </v-tab-item>


        </v-tabs-items> -->


        <user-data-profile-list
          :companies="userData.profile_data"

        />


        <div style="display: none;" @click="functionsInstance.handleErrorFunctionTest2()">
          handle error
        </div>

      </v-col>
    </v-row>


  </div>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant } from '@mdi/js'

// import { ref } from '@vue/composition-api'
import ProfileBioPanel from '@/components/Profile/ProfileBioPanel.vue'
import UserDataProfileList from '@/components/Profile/UserDataProfileList';


export default {
  components: {
    ProfileBioPanel,
    UserDataProfileList,
  },
  data: () => {

    return {
      userData: {
        // avatarImg: require('@/assets/images/avatars/1.png'),
        // username: 'johnDoe',
        // name: 'john Doe',
        // email: 'johnDoe@example.com',
        // role: 'Admin',
        // status: 'Active',
        // company: 'Google.inc',
      },
      userTab: null
    };

  },
  mounted() {
    this.getUserDataForProfile()
  },
  methods: {
    async getUserDataForProfile() {
      try {
        const response = await this.$api.getUserDataForProfile()
        response.role = this.roleParser(response.role)
        // response.avatar = "1.png"
        this.userData = response
      } catch (e) {
        console.log(e);
      }
    },
    roleParser(role) {
      const roles = {
        Admin: "Administrador",
        Worker: "Trabajador",
        Admin_company: "Adm. de empresa"
      }
      return roles[role]
    }
  },


  props: {
    // accountData: {
    //   type: Object,
    //   default: () => {},
    // },
  },
  setup(props) {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']


    const resetForm = () => {
      // accountDataLocale.value = JSON.parse(JSON.stringify(props.accountData))
    }

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
      { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' },
    ]

    return {
      status,
      resetForm,
      tabs,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      functionsInstance: {}
    }
  },

}

</script>
