import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("EMPRESAS")]),_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.companies,"options":_vm.options,"server-items-length":_vm.companies.length,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.getUserRole === 0? 'Un administrador no tiene empresas asociadas' : 'Aún no hay empresas asociadas')+" ")]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"channel-btn",attrs:{"text":""},on:{"click":function($event){return _vm.$refs.company_info_modal.open(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.credits",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.credits? item.credits : '-')+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }