<template>
  <v-dialog
    v-model="isProfileChangePassOpen"
    max-width="650px"
    @click:outside="close()"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Cambiar contraseña de usuario
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form class="multi-col-validation" ref="changePasswordForm">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="old_password"
                ref="old_password"
                outlined
                type="password"
                :rules="[(v) => v && v.length >= 8 || 'Ingrese un mínimo de 8 carácteres.']"
                dense
                label="Contraseña anterior"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                v-model="new_password"
                ref="new_password"
                outlined
                type="password"
                :rules="[(v) => v && v.length >= 8 || 'Ingrese un mínimo de 8 carácteres.']"
                dense
                label="Contraseña nueva"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-text-field
                v-model="confirm_password"
                ref="confirm_password"
                outlined
                type="password"
                :rules="[(v) => v && v.length >= 8 || 'Ingrese un mínimo de 8 carácteres.']"
                dense
                label="Repetir la Nueva Contraseña"
              ></v-text-field>
            </v-col>
            
            <span 
              class="error--text ml-4 error-msg"
              v-if="error_msg"
            >{{ error_msg }}</span>
            
            <!-- <span 
              class="error--text ml-4 error-msg"
              v-if="old_password_error_msg"
            >{{ old_password_error_msg }}</span> -->

            <!-- <span 
              class="error--text ml-4 error-msg"
              v-if="non_field_errors"
            >{{ non_field_errors }}</span> -->

            <v-col
              cols="12"
              class="d-flex justify-end mt-1"
            >
              
              <v-btn
                outlined
                class="me-3"
                color="secondary"
                @click.prevent="close()"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                @click.prevent="onSubmit"
              >
                Cambiar contraseña
              </v-btn>

            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
    return {
      old_password: '',
      new_password: '',
      confirm_password: '',
      // old_password_error_msg: null,
      error_msg: null,
      // non_field_errors:null
    }
  },
  props: {
    isProfileChangePassOpen: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    async onSubmit() {
      if(this.$refs.changePasswordForm.validate()) {
        const form = {
          old_password: this.old_password,
          new_password: this.new_password,
          confirm_new_password: this.confirm_password,
          change_pass_third: 'False',
        }
        try {
          const response = await this.$api.changeUserPasswordProfile(form)
          // this.old_password_error_msg = null
          // this.non_fields_error = null
          this.close()
          
        } catch (e) {
          if (e?.response?.status === 403) {
            this.error_msg = e?.response?.data?.detail
          } else {
            this.error_msg = e?.response?.data?.old_password[0] || e.response?.data?.non_field_errors[0]
          }

        } 
    }
    },
    close() {
      // this.old_password_error_msg = null
      // this.non_fields_error = null
      this.error_msg = null
      this.$refs.old_password.reset()
      this.$refs.new_password.reset()
      this.$refs.confirm_password.reset()
      this.$emit('update:is-profile-change-pass-open', false)
    }
    
  },

 
}
</script>

<style scoped>
  .error-msg {
    font-size: 15px;
  }
</style>