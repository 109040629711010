<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :src="require(`@/assets/images/avatars/${userData.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userData.user) }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.user }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(userData.role)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(userData.role)}--text text-capitalize`"
          >
            {{ userData.role }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Detalles
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Usuario:</span>
              <span class="text--secondary">{{ userData.user }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Nombre:</span>
              <span class="text--secondary">{{ userData.name || 'Sin definir' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ userData.email || 'Sin definir' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Rol:</span>
              <span class="text--secondary text-capitalize">{{ userData.role }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">País:</span>
              <span class="text--secondary text-capitalize">{{ userData.country || 'Sin definir' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
              v-if="getUserRole === 0"
            >
              <span class="font-weight-medium text-no-wrap me-2">Teléfono:</span>
              <span class="text--secondary">{{ `+(${userData.prefix})` + userData.number || 'Sin definir' }}</span>
            </v-list-item>

          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Editar
          </v-btn>
          <v-btn
            color="primary"
            @click="isChangePassOpen = !isChangePassOpen"
          >
            Cambiar Contraseña
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <profile-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
        @reload-data="$emit('reload-data')"
      ></profile-edit>
      
      <profile-change-pass
        :is-profile-change-pass-open.sync="isChangePassOpen"
      />
        <!-- :user-data="userData" -->
        <!-- @reload-data="$emit('reload-data')" -->
    </v-col>

  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import ProfileEdit from './ProfileEdit.vue'
import ProfileChangePass from './ProfileChangePass.vue'
import useUsersList from '../Messages/useUsersList' /* front/materio/src/views/apps/user/user-list/useUsersList.js */

export default {
  components: {
    ProfileEdit,
    ProfileChangePass
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    // isPlanUpgradeDialogVisible: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {
    const { resolveUserStatusVariant, resolveUserRoleVariant } = useUsersList()

    const isBioDialogOpen = ref(false)
    const isChangePassOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      isChangePassOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
